<template>
  <new-sub-modal></new-sub-modal>
</template>

<script>
import NewSubModal from "@/components/modals/new_sub/NewSubModal";
import {useNewAboModalStore} from "@/store/modals/new-abo-modal-store";
import {useRoute} from "vue-router";
import {onMounted} from "vue"
import {useSubStore} from "@/store/subs/sub-store";
import {subBookedStep} from "@/configurations/payment/payment-config";

export default {
  name: 'Success',
  components: {NewSubModal},
  setup() {
    const route = useRoute()
    const newAboModalStore = useNewAboModalStore();
    const subStore = useSubStore()

    onMounted(() => {
      subStore.finalPaymentProcess('SUCCESS', route.params.payment_token)
    })

    newAboModalStore.toggle()
    newAboModalStore.disableClosable();
    newAboModalStore.goToStep(subBookedStep)
  }
}
</script>

<style scoped>
</style>
